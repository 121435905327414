import gql from 'graphql-tag';
import apolloClient from '../apollo';
import dayjs from 'dayjs';
import Vue from 'vue';

import 'dayjs/locale/de';

/**
 * State
 */
const getDefaultState = () => {
  return {
    audits: [],
    auditCount: 0,
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an audit
 * used for all queries/mutations which return an audit
 * @type {DocumentNode}
 */
const AUDIT_REQUEST_FIELDS = gql`
  fragment auditFields on Audit {
    _id
    user {
      firstname
      lastname
      email
    }
    resourceId
    resourceType
    relatedResource {
      resourceType
    }
    action
    payload
    fieldPath
    newValue
    oldValue
    createdAt
  }
`;

/**
 * Actions
 */
const actions = {
  async fetchAudits({ commit, dispatch }, { pagination, sorting, filters }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query audits(
            $pagination: PaginationInput
            $sorting: SortingInput
            $filters: AuditFilterInput
          ) {
            audits(pagination: $pagination, sorting: $sorting, filters: $filters) {
              audits {
                ...auditFields
              }
              auditCount
            }
          }
          ${AUDIT_REQUEST_FIELDS}
        `,
        variables: {
          pagination: pagination,
          sorting: sorting,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });

      commit('SET_AUDITS', response.data.audits.audits);
      commit('SET_AUDIT_COUNT', response.data.audits.auditCount);
    } catch (err) {
      throw new Error(err);
    }
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_AUDITS: (state, audits) => (audits ? (state.audits = audits) : (state.audits = [])),
  ADD_AUDIT: (state, audit) => state.audits.push(audit),
  SET_AUDIT_COUNT: (state, auditCount) => (state.auditCount = auditCount),
};

/**
 * Getters
 */
const getters = {
  getAudits: (state) => {
    return state.audits;
  },
  getAudit: (state) => (auditId) => state.audits.find((audit) => audit.id == auditId),
  getAuditCount: (state) => state.auditCount,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
